/* Carousel.css */
.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.carousel-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-image {
    width: 100%;
    flex: 0 0 auto;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 2;
    font-size: 4vw;
}

.carousel-button-left {
    left: 10px;
}

.carousel-button-right {
    right: 10px;
}

.fixed-caption {
    position: absolute;
    top: min(75vh, 70%); /* Move to the middle vertically */
    left: 50%; 
    transform: translate(-50%, -50%); /* Center the caption both vertically and horizontally */
    color: #fff; /* White text */
    font-size: 5vw; /* Bigger font size */
    z-index: 2; /* Make sure it's above the images */
    width: 80%;
}

.marketing {
    margin-top: 40px;
}

.marketing {
    margin-top: 40px;
  }
  
  /* Center align the text within the three columns below the carousel */
  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  /* rtl:begin:ignore */
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }