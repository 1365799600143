/* Styling for gallery items, for example, images */
.gallery-item {
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery .modal img {
    position: fixed; /* this is added */
    max-width: 80%;
    max-height: 80%;
}

.gallery .container {
    top: 30px; /* Adjust this value according to your preference */
    position:relative;
}

.gallery .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    animation: enlargeModal 0.5s forwards;
}

.gallery .img-thumbnail {
    position: relative;
    transition: transform 0.3s ease-in-out;
    aspect-ratio: 1;
    object-fit: cover; 
    z-index:0.3s;
}


.gallery .img-thumbnail:hover {
    transform: scale(1.1);
    z-index:10;
}
 

@keyframes enlargeModal {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .img-thumbnail:hover {
        transform: scale(1);
        z-index:10;
    }
}
