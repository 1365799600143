.navbar {
    background-color: #333;
    padding: 10px 20px;
    color: #fff;
}
  
.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This takes the full viewport height */
}

.contact-info {
    padding: 20px;
    border: 1px solid #e0e0e0;
    width: 300px;
}

.contact-item {
    margin-bottom: 20px;
}

.instagram-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #E4405F; /* Instagram color */
    margin-top: 10px;
}
  
.instagram-logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
  