/* style/events.css */
.event-page .container {
  top: 30px; /* Adjust this value according to your preference */
  position:relative;
}

.event-section {
  margin-bottom: 30px;
}

.event-section h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.event-section p {
  margin-bottom: 10px;
}

.event-section .modal img {
  position: fixed; /* this is added */
  max-width: 80%;
  max-height: 80%;
}

.event-section .container {
  top: 30px; /* Adjust this value according to your preference */
  position:relative;
}

.event-section .bold
{
  font-weight: bold;
}

.rounded-border-section {
  border: 2px solid #ccc; /* Example border color */
  border-radius: 15px;    /* Adjust the px value to get the desired roundness */
  padding: 20px;          /* Add padding to ensure content doesn't touch the border */
  margin-top: 20px;       /* Optional: Adds some space above the section */
  background-color: #fff; /* Optional: Sets a background color */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}


.event-section .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  animation: enlargeModal 0.5s forwards;
}

.event-section .img-thumbnail {
  position: relative;
  transition: transform 0.3s ease-in-out;
  aspect-ratio: 1;
  object-fit: cover; 
  z-index:0.3s;
}


.event-section .img-thumbnail:hover {
  transform: scale(1.1);
  z-index:10;
}


@keyframes enlargeModal {
  0% {
      transform: scale(0.1);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

@media (max-width: 768px) {
  .img-thumbnail:hover {
      transform: scale(1);
      z-index:10;
  }
}


/* Add any specific styles for your GalleryModal if needed */
