.store .container {
    top: 30px; /* Adjust this value according to your preference */
    position:relative;
}

.painting-card {
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.painting-card img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 0; /* This ensures the image always stays at the bottom layer */
    position: relative; /* Needed for z-index to work */
}


.painting-details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 2; /* This will make sure the details always stay on top */
}

.painting-price {
    font-weight: bold;
    z-index: 11;
}

.painting-author {
    display: none;
    font-size: 0.9em;
    z-index: 11;
}

.painting-card:hover .painting-author{
    display:block;
}

.painting-title {
    display: none; /* Initially hiding the description to be shown on hover */
    z-index: 11; 
    z-index: 11;
}

.painting-card:hover .painting-title {
    display: block;
}

.painting-details p {
    margin-top: 1px;
    margin-bottom: 1px;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 5px;
}

.painting-card .img-thumbnail {
    position: relative;
    transition: transform 0.3s ease-in-out;
    aspect-ratio: 1;
    object-fit: cover; 
}


.painting-card:hover .img-thumbnail {
    transform: scale(1.1);
}

.painting-modal img {
    max-width: 80%;
    max-height: 80%;
    z-index: 100;
}

.painting-modal {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    animation: enlargeModal 0.5s forwards;
}

.painting-modal .modal-content {
    display: block;
    align-items: center;
    width: 80%; 
    height: 80%; 
    background-color: rgba(0, 0, 0, 0.8); 
    padding: 20px; 
    border-radius: 10px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.painting-modal .modal-description {
    flex: 1;
    color: #fff;
    overflow-y: auto;
}

.painting-modal img {
    flex-basis: 50%;
    max-height: 100%;
    object-fit: contain;
}

.painting-modal .modal-content .image-container {
    height: 80vh;  
    overflow: hidden; 
}

.painting-modal .modal-content .modal-image {
    height: 90%;  
    object-fit: contain;
    width: auto;  
    max-width: 90%;
}



@keyframes enlargeModal {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
